export const getReports = async (userId, sortField, sortDirection, reportType = 'all') => {
    return await fetch(`/api/report/${userId}?sortField=${sortField}&sortDirection=${sortDirection}`, {
        credentials: 'include',
    });
}

// sortField, sortDirection, reportType, status, page, limit 
export const getReportsNew = async ({sortField, sortDirection, reportType, status, page = 1, limit, searchQuery}) => {
    let query = '';
    const args = { sortField, sortDirection, reportType, status, page, limit, searchQuery };
    for (let key in args) {
        if (args[key])
            query += `${key}=${args[key]}&`;
    }
    return await fetch(`/api/report/all?${query}`, {
        credentials: 'include',
    });
}

export const getReport = async (reportId) => {
    return await fetch(`/api/report/single/${reportId}`, {
        credentials: 'include',
    });
}

export const getReportStatus = async (reportId) => {
    return await fetch(`/api/report/status/${reportId}`, {
        credentials: 'include',
    });
}

export const createPdf = async (report, single) => {
    try {
        const response = await fetch(`/api/report/pdf/${report._id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ single }),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const fileName = single ? `${report.reportPatients[single.patientId].name} - ${report.reportType.toUpperCase()} Report.pdf` : `${report.reportName}.pdf`;
        return { blob, fileName };
    } catch (error) {
        console.error('PDF Download error:', error);
        throw error; // Rethrow to allow caller to handle the error
    }
}

export const updateReport = async (accountId, report) => {
    return await fetch('/api/report/recalculate', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accountId, report }),
        credentials: 'include',
    });
}

export const deleteManyReports = async (reportIds) => {
    return await fetch('/api/report/delete-many', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reportIds }),
        credentials: 'include',
    });
}

export const countStatuses = async () => {
    return await fetch('/api/report/count/status', {
        credentials: 'include',
    });
}